<template>
  <v-container>
    <v-row justify="center">
      <!-- Project Code Select -->
      <v-col cols="12" md="8">
        <v-card class="mb-4 pa-4" outlined>
          <v-card-title>Select Project Code</v-card-title>
          <v-card-subtitle class="text--secondary">
            Choose a project code to load users and project data.
          </v-card-subtitle>
          <v-divider class="my-4"></v-divider>
          <v-select
            v-model="selectedProjectCode"
            :items="formattedProjectCodes"
            item-text="label"
            item-value="code"
            label="Select Project Code"
            outlined
            dense
            class="mb-4"
          ></v-select>
          <v-row>
            <v-col>
              <v-btn
                :disabled="!selectedProjectCode"
                @click="getUsersByProjectCodes"
                color="primary"
                large
                block
                elevation="2"
              >
                Get Users
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                :disabled="!selectedProjectCode"
                @click="getProjectData"
                color="primary"
                large
                block
                elevation="2"
              >
                Get Project Data
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- User Count Card -->
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card class="pa-4 mb-4" outlined>
          <v-card-title class="d-flex justify-center">
            <h2 class="text-h4">Total Users: {{ count }}</h2>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <!-- Data Expansion Panels -->
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-expansion-panels>
          <!-- Users Expansion Panel -->
          <v-expansion-panel>
            <v-expansion-panel-header> Users Data </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field
                v-model="userSearch"
                label="Search Users"
                prepend-icon="mdi-magnify"
                outlined
                dense
                class="mb-4"
              ></v-text-field>
              <v-btn
                :loading="isExportingUsers"
                :disabled="!filteredUsers.length"
                @click="exportUsersToCSV"
                color="success"
                large
                block
                elevation="2"
                class="mb-4"
              >
                Export Users
              </v-btn>
              <v-data-table
                v-if="filteredUsers.length"
                :headers="userHeaders"
                :items="filteredUsers"
                class="elevation-1 mb-4"
                :items-per-page="10"
                dense
              ></v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Project Data Expansion Panel -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              Project Data - Total Records {{ projectData.length }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field
                v-model="projectSearch"
                label="Search Project Data"
                prepend-icon="mdi-magnify"
                outlined
                dense
                class="mb-4"
              ></v-text-field>

              <!-- Export to CSV Button -->
              <v-btn
                :loading="isExporting"
                :disabled="!projectData.length"
                @click="exportProjectDataToCSV"
                color="success"
                large
                block
                elevation="2"
                class="mb-4"
              >
                Export Project Data to CSV
              </v-btn>

              <!-- Project Data Table -->
              <v-data-table
                v-if="filteredProjectData.length"
                :headers="projectHeaders"
                :items="filteredProjectData"
                class="elevation-1 mb-4"
                :items-per-page="10"
                dense
              ></v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RestResource from "../../../services/dataServiceMobileV2.js";
const service = new RestResource();

export default {
  data() {
    return {
      projectCode: [],
      projectData: [],
      selectedProjectCode: null,
      users: [],
      count: 0,
      userSearch: "",
      projectSearch: "",
      isExporting: false,
      isExportingUsers: false,
      userHeaders: [
        { text: "User ID", value: "_id" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phoneNumber" },
      ],
      projectHeaders: [
        { text: "Submission Id", value: "_id" },
        { text: "User Id", value: "userId" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phoneNumber" },
      ],
    };
  },

  computed: {
    formattedProjectCodes() {
      return this.projectCode.map((project) => ({
        label: `${project.code} - ${project.projectName}`,
        code: project.code,
      }));
    },
    filteredUsers() {
      const search = this.userSearch.toLowerCase();
      return this.users.filter((user) =>
        Object.values(user).some((value) =>
          String(value).toLowerCase().includes(search)
        )
      );
    },
    filteredProjectData() {
      const search = this.projectSearch.toLowerCase();
      return this.projectData.filter((data) =>
        Object.values(data).some((value) =>
          String(value).toLowerCase().includes(search)
        )
      );
    },
  },

  mounted() {
    this.getProjectCodes();
  },

  methods: {
    async getProjectCodes() {
      this.$setLoader();
      let res = await service.getProjectCodes();
      this.projectCode = res.data.data;
      this.$disableLoader();
    },

    async getUsersByProjectCodes() {
      if (!this.selectedProjectCode) return;
      this.$setLoader();
      let res = await service.getUsersByProjectCodes({
        code: this.selectedProjectCode,
      });
      this.users = res.data.data.users;
      this.count = res.data.data.count;
      this.$disableLoader();
    },

    async getProjectData() {
      if (!this.selectedProjectCode) return;
      this.$setLoader();
      let res = await service.getProjectData({
        projectCode: this.selectedProjectCode,
      });
      this.projectData = res.data.data;
      this.$disableLoader();
    },

    async exportProjectDataToCSV() {
      if (!this.projectData.length) return;
      this.isExporting = true;

      const selectedProject = this.projectCode.find(
        (project) => project.code === this.selectedProjectCode
      );
      const projectName = selectedProject
        ? selectedProject.projectName
        : "Unknown_Project";

      const today = new Date();
      const dateStr = today.toISOString().split("T")[0];

      // Define headers explicitly (excluding `updatedAt` and `isCoded`)
      const headers = [
        "_id",
        "email",
        "phoneNumber",
        "userId",
        "brandName",
        "packSize",
        "shopImage",
        "receiptImage",
        "beverageImage",
        "price",
        "reason",
        "drinkWhere",
        "quantityBought",
        "quantityDrank",
        "promotion",
        "occasion",
        "company",
        "purchaseType",
        "projectCode",
        "createdAt", // Modified for time adjustment
      ];

      // Create the CSV header row
      const headerRow = headers.join(",");

      // Map the project data to rows using the headers
      const rows = this.projectData.map((item) => {
        const adjustedItem = { ...item };

        // Add 8 hours to createdAt field
        if (adjustedItem.createdAt) {
          const createdAtDate = new Date(adjustedItem.createdAt);
          createdAtDate.setHours(createdAtDate.getHours() + 8);
          adjustedItem.createdAt = createdAtDate.toISOString(); // Format as ISO string
        }

        return headers
          .map(
            (field) =>
              `"${String(adjustedItem[field] || "").replace(/"/g, '""')}"`
          )
          .join(",");
      });

      // Combine the header row and data rows
      const csvContent = [headerRow, ...rows].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${dateStr}_${projectName}.csv`;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.isExporting = false;
    },

    async exportUsersToCSV() {
      if (!this.filteredUsers.length) return;
      this.isExportingUsers = true;

      const today = new Date();
      const dateStr = today.toISOString().split("T")[0];

      const headers = ["_id", "firstName", "lastName", "email", "phoneNumber"];
      const headerRow = headers.join(",");

      const rows = this.filteredUsers.map((user) =>
        headers
          .map((field) => `"${String(user[field] || "").replace(/"/g, '""')}"`)
          .join(",")
      );

      const csvContent = [headerRow, ...rows].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${dateStr}_Users.csv`;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.isExportingUsers = false;
    },
  },
};
</script>

<style scoped>
.v-container {
  max-width: 800px;
  margin: auto;
}

.v-card-title {
  font-weight: bold;
}

.v-btn {
  margin-bottom: 12px;
}

.v-expansion-panel-header {
  font-weight: 600;
}
</style>
