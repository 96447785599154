<template>
  <v-card>
    <div class="table-container">
      <v-card-title>Edit User Submissions</v-card-title>
      <v-card-text>Total Submissions: {{ data.length }}</v-card-text>
      <div
        v-for="(item, index) in data"
        :key="item._id"
        class="item-container mt-5"
      >
        <div class="action-buttons">
          <v-btn
            :disabled="item.isCoded"
            color="primary"
            @click="saveItem(index)"
            >Update Submission</v-btn
          >
        </div>
        <span v-if="item.isCoded" class="submitted-text">Data Submitted</span>
        <span v-else class="pending-text">Data Entry Pending</span>
        <h3 class="mt-4">Submission {{ index + 1 }}</h3>

        <v-row class="mt-4 mb-4">
          <v-col cols="12" sm="6">
            <div class="form-row">
              <label>Beverage Image</label>
              <img
                :src="item.beverageImage"
                alt="Beverage Image"
                class="image-preview"
              />
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div class="form-row">
              <label>Shop Image</label>
              <img
                :src="item.shopImage"
                alt="Shop Image"
                class="image-preview"
              />
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-4 mb-4">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-text-field
              type="number"
              v-model="item.flavour"
              label="Enter Flavour Code"
              placeholder="Coca-Cola Original = 2"
              outlined
              persistent-hint
              hint="Code it from the beverage image."
              :disabled="item.isCoded"
            />
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-text-field
              type="number"
              v-model="item.format"
              label="Enter Format Code"
              placeholder="Bottle = 2"
              outlined
              persistent-hint
              hint="Code it from the beverage image."
              :disabled="item.isCoded"
            />
          </v-col>
        </v-row>

        <v-row class="mt-4 mb-4">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-text-field
              type="number"
              v-model="item.shop"
              label="Enter Shop Code"
              placeholder="Hotel / Resorts = 5"
              outlined
              persistent-hint
              hint="Code it from the Shop image."
              :disabled="item.isCoded"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="item.packSize"
              label="Enter Pack Code"
              type="number"
              placeholder="Can 300 ml = 3"
              outlined
              persistent-hint
              hint="Code it from the Shop image."
              :disabled="item.isCoded"
            />
          </v-col>
        </v-row>

        <v-row class="mt-4 mb-4">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="item.brandName"
              label="Brand Name"
              placeholder="Enter brand name"
              outlined
              disabled
            />
          </v-col>
          <v-col>
            <v-text-field
              type="number"
              v-model="item.brandCode"
              label="Enter Brand Code"
              placeholder="Fanta = 5"
              outlined
              persistent-hint
              hint="Code it from the Brand mentioned."
            />
          </v-col>
        </v-row>

        <v-row class="mt-4 mb-4">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="item.dayCode"
              label="Day Code"
              placeholder="1"
              outlined
              disabled
              persistent-hint
              hint="Calculated Automatically"
            />
          </v-col>
          <v-col>
            <v-text-field
              type="number"
              v-model="item.timeCode"
              label="Time Code"
              placeholder="1"
              outlined
              disabled
              persistent-hint
              hint="Calculated Automatically"
            />
          </v-col>
        </v-row>

        <v-text-field
          v-model="item.createdAt"
          label="Created Date"
          disabled
          outlined
        />

        <v-text-field
          v-model="item.price"
          label="Price"
          placeholder="Enter price"
          outlined
          disabled
        />
        <v-text-field
          v-model="item.quantityDrank"
          label="Quantity Drank"
          placeholder="Enter quantity drank"
          outlined
          disabled
        />
        <v-text-field
          v-model="item.quantityBought"
          label="Quantity Bought"
          placeholder="Enter quantity bought"
          outlined
          disabled
        />
        <v-text-field
          v-model="item.reason"
          label="Reason"
          placeholder="Enter reason"
          disabled
          outlined
        />
        <v-text-field
          v-model="item.drinkWhere"
          label="Drink Where"
          disabled
          placeholder="Enter where it was drunk"
          outlined
        />

        <v-text-field
          v-model="item.promotion"
          label="Promotion"
          placeholder="Enter promotion"
          disabled
          outlined
        />
        <v-text-field
          v-model="item.occasion"
          label="Occasion"
          placeholder="Enter occasion"
          disabled
          outlined
        />
        <v-text-field
          v-model="item.company"
          label="Company"
          placeholder="Enter company"
          disabled
          outlined
        />
        <v-text-field
          v-model="item.purchaseType"
          label="Purchase Type"
          placeholder="Enter purchase type"
          disabled
          outlined
        />
        <v-text-field
          v-model="item.updatedAt"
          label="Updated Date"
          disabled
          outlined
        />

        <div class="form-row">
          <label>Receipt Image</label>
          <img
            :src="item.receiptImage"
            alt="Receipt Image"
            class="image-preview"
          />
        </div>
        <div class="form-row">
          <label>Location</label>
          <Map
            :coordinates="formatCoordinates(item.location.coordinates)"
            :zoom="12"
          />
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import Map from "../../../components/Map.vue";
import RestResource from "../../../services/dataServiceMobileV2.js";
const service = new RestResource();

export default {
  components: {
    Map,
  },
  data() {
    return {
      data: [],
    };
  },
  async created() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        const response = await service.getAllSubmissions({
          userId: this.$route.params.userId,
        });
        this.data = response.data.data.map((item) => ({
          ...item,
          timeCode: this.calculateTimeCode(item.createdAt),
          dayCode: this.calculateDayCode(item.createdAt),
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    
    async saveItem(index) {
      this.$setLoader();
      try {
        const { _id, createdAt, purchaseType, ...rest } = this.data[index];

        const malaysianTime = new Date(this.convertToMalaysianTime(createdAt));

        const formattedPurchaseType =
          purchaseType ===
          "Yes the drink/bill was split among me and others (Pls specify number of people)"
            ? "1"
            : "2";

        const updatedItem = {
          ...rest,
          submissionId: _id,
          submissionDate: malaysianTime,
          purchaseType: formattedPurchaseType,
          projectCode: "prj100",
          hasBrandCode: true,
        };

        await service.updateSubmission(updatedItem);

        await this.getData();
      } catch (error) {
        console.error(error);
      } finally {
        this.$disableLoader();
      }
    },

    formatCoordinates(coor) {
      return coor.map((coord) => coord.toFixed(7)).join(", ");
    },

    calculateTimeCode(submissionDate) {
      const malaysianTime = this.convertToMalaysianTime(submissionDate);
      const hour = malaysianTime.getHours();

      if (hour >= 6 && hour < 12) return 1; // Morning
      if (hour === 12) return 2; // Noon
      if (hour >= 13 && hour < 18) return 3; // Afternoon
      if (hour >= 18 && hour < 21) return 4; // Evening
      return 5; // Night
    },

    calculateDayCode(submissionDate) {
      const malaysianTime = this.convertToMalaysianTime(submissionDate);
      const day = malaysianTime.getDay();
      return day === 0 || day === 6 ? 2 : 1;
    },

    convertToMalaysianTime(date) {
      const malaysianOffset = 8 * 60;
      const dateObj = new Date(date);
      const utcTime =
        dateObj.getTime() + dateObj.getTimezoneOffset() * 60 * 1000;
      return new Date(utcTime + malaysianOffset * 60 * 1000);
    },
  },
};
</script>

<style scoped>
.table-container {
  margin: 20px auto;
  width: 90%;
  max-width: 800px;
  font-family: Arial, sans-serif;
  text-align: left;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.item-container {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

h3 {
  color: #4caf50;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  color: #555;
  width: 150px;
}

.form-group {
  flex: 1;
  margin-right: 20px;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.input-field:focus {
  border-color: #4caf50;
  outline: none;
}

.image-preview {
  border-radius: 4px;
  margin-top: 10px;
  width: 300px;
  height: auto;
  max-height: 300px;
  object-fit: contain;
}

.action-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}

.save-btn {
  background-color: #4caf50;
}

.delete-btn {
  background-color: #f44336;
}

.delete-btn:hover {
  background-color: #e53935;
}

.submitted-text {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #28a745;
  text-align: center;
  background-color: #e0f7e0;
  padding: 5px;
  border-radius: 5px;
}

.pending-text {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #721c24;
  text-align: center;
  background-color: #f8d7da;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #f5c6cb;
}
</style>
