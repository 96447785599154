<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-card outlined class="pa-4">
          <v-card-title>
            <v-icon class="mr-2" color="primary">mdi-database</v-icon>
            Project Data Management
          </v-card-title>
          <v-card-subtitle>
            Manage, search, and export project data effortlessly.
          </v-card-subtitle>

          <v-divider class="my-4"></v-divider>

          <!-- API Key Input -->
          <v-text-field
            v-model="apiKey"
            label="Enter API Key"
            prepend-icon="mdi-key"
            outlined
            dense
            type="password"
            @blur="saveApiKey"
          ></v-text-field>

          <v-divider class="my-4"></v-divider>

          <!-- Search Bar -->
          <v-text-field
            v-model="projectSearch"
            label="Search Submission"
            prepend-icon="mdi-magnify"
            outlined
            dense
            class="mb-4"
          ></v-text-field>

          <v-row>
            <v-col cols="12" md="6">
              <v-btn @click="getData" color="success" large block elevation="2">
                <v-icon class="mr-2">mdi-file-export</v-icon>
                Get Data
              </v-btn>
            </v-col>
          </v-row>

          <!-- Export Buttons -->
          <v-row>
            <v-col cols="12" md="6">
              <v-btn
                :loading="isExporting"
                :disabled="!projectData.length"
                @click="exportProjectDataToCSV"
                color="success"
                large
                block
                elevation="2"
              >
                <v-icon class="mr-2">mdi-file-export</v-icon>
                Export Project Data to CSV
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                :loading="isExporting"
                :disabled="!data.length"
                @click="exportUsersToCSV"
                color="primary"
                large
                block
                elevation="2"
              >
                <v-icon class="mr-2">mdi-account-export</v-icon>
                Export Users
              </v-btn>
            </v-col>
          </v-row>

          <v-divider class="my-4"></v-divider>

          <!-- Project Data Table -->
          <v-data-table
            v-if="filteredProjectData.length"
            :headers="projectHeaders"
            :items="filteredProjectData"
            class="elevation-1 mb-4"
            :items-per-page="20"
            dense
          >
          </v-data-table>

          <!-- Empty State -->
          <v-alert v-else type="info" border="left" colored-border class="mb-4">
            No project data available.
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RestResource from "../services/dataServiceVendor";
import ApiUtil from "../../ApiUtil";
const service = new RestResource();

export default {
  data() {
    return {
      projectData: [],
      users: [],
      apiKey: ApiUtil.getApiKey() || "",
      data: [],
      userSearch: "",
      projectSearch: "",
      selectedProjectCode: "prj100",
      isExporting: false,
      isExportingUsers: false,
      projectHeaders: [
        { text: "Submission Id", value: "_id" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "Submission Date", value: "createdAt" },
      ],
    };
  },

  computed: {
    filteredProjectData() {
      const search = this.projectSearch.toLowerCase();
      return this.projectData
        .map((data) => {
          const adjustedData = { ...data };

          if (adjustedData.createdAt) {
            const date = new Date(adjustedData.createdAt);
            date.setHours(date.getHours() + 8);
            adjustedData.createdAt = date.toISOString();
          }

          return adjustedData;
        })
        .filter((data) =>
          Object.values(data).some((value) =>
            String(value).toLowerCase().includes(search)
          )
        );
    },
  },

  methods: {
    async getGroupedData() {
      this.$setLoader();
      try {
        const response = await service.getGroupedSubmission({
          projectCode: "prj100",
          showEmptyData: true,
        });
        this.data = response.data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.$disableLoader();
      }
    },

    async getData() {
      if (!this.apiKey) {
        alert("Please provide an API key to continue.");
        return;
      }
      await this.getProjectData();
      await this.getGroupedData();
    },

    saveApiKey() {
      if (this.apiKey) {
        ApiUtil.setApiKey(this.apiKey);
      }
    },

    async getProjectData() {
      try {
        this.$setLoader();
        let res = await service.getProjectData({
          projectCode: this.selectedProjectCode,
        });
        this.projectData = res.data.data;
      } catch (error) {
        if (error.response) {
            alert(error.response.data.message)
        }
      } finally {
        this.$disableLoader();
      }
    },

    async exportProjectDataToCSV() {
      if (!this.projectData.length) return;
      this.isExporting = true;

      const projectName = "Vendor";

      const today = new Date();
      const dateStr = today.toISOString().split("T")[0];

      // Define headers explicitly (excluding `updatedAt` and `isCoded`)
      const headers = [
        "_id",
        "email",
        "phoneNumber",
        "userId",
        "brandName",
        "packSize",
        "shopImage",
        "receiptImage",
        "beverageImage",
        "price",
        "reason",
        "drinkWhere",
        "quantityBought",
        "quantityDrank",
        "promotion",
        "occasion",
        "company",
        "purchaseType",
        "projectCode",
        "createdAt",
      ];

      const headerRow = headers.join(",");

      const rows = this.projectData.map((item) => {
        const adjustedItem = { ...item };

        if (adjustedItem.createdAt) {
          const createdAtDate = new Date(adjustedItem.createdAt);
          createdAtDate.setHours(createdAtDate.getHours() + 8);
          adjustedItem.createdAt = createdAtDate.toISOString();
        }

        return headers
          .map(
            (field) =>
              `"${String(adjustedItem[field] || "").replace(/"/g, '""')}"`
          )
          .join(",");
      });

      // Combine the header row and data rows
      const csvContent = [headerRow, ...rows].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${dateStr}_${projectName}.csv`;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.isExporting = false;
    },

    async exportUsersToCSV() {
      if (!this.data.length) return;
      this.isExportingUsers = true;

      const today = new Date();
      const dateStr = today.toISOString().split("T")[0];

      const headers = ["email", "totalSubmissions"];
      const headerRow = headers.join(",");

      const rows = this.data.map((d) =>
        headers
          .map(
            (field) =>
              `"${String(
                d[field] !== undefined && d[field] !== null ? d[field] : 0
              ).replace(/"/g, '""')}"`
          )
          .join(",")
      );

      const csvContent = [headerRow, ...rows].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${dateStr}_User_Submission_data.csv`;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.isExportingUsers = false;
    },
  },
};
</script>

<style scoped>
.v-container {
  max-width: 1200px;
  margin: auto;
}

.v-card-title {
  font-weight: bold;
  font-size: 1.5rem;
}

.v-card-subtitle {
  font-size: 1rem;
  color: gray;
}

.v-btn:hover {
  transform: scale(1.02);
  transition: transform 0.2s ease-in-out;
}

.v-alert {
  text-align: center;
  font-size: 1.1rem;
}
</style>
