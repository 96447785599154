export default class Util {
    /**
     * Sets the API key in localStorage
     * @param {string} apiKey - The API key to store
     */
    static setApiKey(apiKey) {
      if (apiKey) {
        localStorage.setItem("apiKey", apiKey);
      } else {
        console.warn("Attempted to set an invalid API key.");
      }
    }
  
    /**
     * Retrieves the API key from localStorage
     * @returns {string|null} - The stored API key, or null if not found
     */
    static getApiKey() {
      return localStorage.getItem("apiKey");
    }
  
    /**
     * Sets up headers for Axios requests, including the API key if available
     * @returns {object} - Configured headers
     */
    static setupHeaders() {
      const apiKey = this.getApiKey(); // Fetch the stored API key
      return {
        headers: {
          "Content-Type": "application/json",
          Authorization: apiKey ? `Bearer ${apiKey}` : "", // Add API key if available
        },
      };
    }
  
    /**
     * Clears the API key from localStorage
     */
    static clearApiKey() {
      localStorage.removeItem("apiKey");
    }
  }
  