<template>
  <div>
    <v-card class="mx-auto">
      <v-app-bar dark color="primary">
        <v-icon large class="mr-3">list_alt</v-icon>
        <v-toolbar-title>Total Submissions</v-toolbar-title>
      </v-app-bar>
      <v-row class="pa-4">
        <!-- Search Field -->
        <v-col cols="12" md="4">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dense
            outlined color="primary" clearable></v-text-field>
        </v-col>

        <!-- Export Button -->
        <v-col cols="12" md="4" class="d-flex align-center">
          <v-btn :disabled="data.length === 0" @click="exportUsersToCSV" color="primary" large block elevation="4"
            class="text-transform-none">
            <v-icon left>mdi-download</v-icon>
            Export Total Submission Count
          </v-btn>
        </v-col>

        <v-col cols="12" md="4" class="d-flex align-center">
          <v-btn :disabled="data.length === 0" @click="exportWweeklyData" color="secondary" large block elevation="4"
            class="text-transform-none">
            <v-icon left>mdi-download</v-icon>
            Export Weekly Submission
          </v-btn>
        </v-col>
      </v-row>

      <v-expansion-panels v-if="data.length > 0" class="mb-4">
        <v-expansion-panel>
          <v-expansion-panel-header>Filter Options</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <!-- Checkbox -->
              <v-col cols="12" md="4">
                <v-checkbox v-model="showEmptyData" label="Show Empty Data" color="primary"></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox v-model="showCodedData" label="Dont show coded data" color="primary"></v-checkbox>
              </v-col>
              <!-- Filter Button -->
              <v-col cols="12" md="4" class="d-flex align-center">
                <v-btn color="primary" large block elevation="4" @click="getGroupedData" class="text-transform-none">
                  <v-icon left>mdi-filter</v-icon>
                  Apply Filter
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-data-table :search="search" :headers="headers" :items="data" class="elevation-1">
        <template v-slot:item.view="{ item }">
          <v-btn text color="primary" @click="goToEdit(item.userId)">
            View All Submissions
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import RestResource from "../../../services/dataServiceMobileV2.js";
const service = new RestResource();

export default {
  data() {
    return {
      data: [],
      weeklyData: [],
      search: "",
      isExportingUsers: false,
      showEmptyData: false,
      showCodedData: false,
      headers: [
        { text: "User Id", value: "userId" },
        { text: "Email", value: "email" },
        {
          text: "Total Submissions",
          value: "totalSubmissions",
          sortable: true,
        },
        { text: "View Submission", value: "view" },
      ],
    };
  },

  mounted() {
    this.getGroupedData();
    this.getWeeklyData();
  },

  methods: {
    async getGroupedData() {
      this.$setLoader();
      try {
        const response = await service.getGroupedSubmission({
          projectCode: "prj100",
          showEmptyData: this.showEmptyData,
          showCodedData: this.showCodedData,
        });
        this.data = response.data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.$disableLoader();
      }
    },

    async getWeeklyData() {
      this.$setLoader();

      try {
        const response = await service.getWeeklyData()
        this.weeklyData = response.data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.$disableLoader();
      }
    },  

    goToEdit(userId) {
      const url = `/mobile/v2/project/data/edit/${userId}`;
      window.open(url, "_blank");
    },

    async exportUsersToCSV() {
      if (!this.data.length) return;
      this.isExportingUsers = true;

      const today = new Date();
      const dateStr = today.toISOString().split("T")[0];

      const headers = ["email", "totalSubmissions"];
      const headerRow = headers.join(",");

      const rows = this.data.map((d) =>
        headers
          .map(
            (field) =>
              `"${String(
                d[field] !== undefined && d[field] !== null ? d[field] : 0
              ).replace(/"/g, '""')}"`
          )
          .join(",")
      );

      const csvContent = [headerRow, ...rows].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${dateStr}_total_Submissions.csv`;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.isExportingUsers = false;
    },

    formatWeeks(data) {
      // Get unique weeks and assign sequential numbers
      const weekMapping = {};
      let weekCounter = 1;

      data.forEach((item) => {
        if (!weekMapping[item.week]) {
          weekMapping[item.week] = weekCounter++;
        }
      });

      // Replace the week with its corresponding sequential number
      return data.map((item) => ({
        ...item,
        week: weekMapping[item.week].toString(),
      }));
    },

    exportWweeklyData() {
      if (!this.weeklyData.length) return;
      const formattedData = this.formatWeeks(this.weeklyData);

      const today = new Date();
      const dateStr = today.toISOString().split("T")[0];

      const csvHeaders = ["alcoholic", "others", "userId", "email", "week"];

      const csvRows = formattedData.map((item) => [
        item.alcoholic,
        item.nonAlcoholic,
        item.userId,
        item.email,
        item.week,
      ]);
      let csvContent = [csvHeaders, ...csvRows]
        .map((row) => row.join(","))
        .join("\n");

      // Create a blob and download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${dateStr}_weekly_Submissions.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
};
</script>
