import axios from "axios";
import Util from "../../Util.js";
import ApiUtil from '../../ApiUtil.js';

var ADMIN_BACKEND_URL = process.env.VUE_APP_ADMIN_BACKEND_URL;

export default class RestResource {
  // TODO: Double check this
  async createUser(user) {
    const url = `${ADMIN_BACKEND_URL}/vendor/createUser`;
    return await axios.post(url, user, Util.setupHeaders());
  }

  async fetchUsers(data) {
    const url = `${ADMIN_BACKEND_URL}/vendor/fetchUsers`;
    return await axios.post(url, data, Util.setupHeaders());
  }

  async fetchUser(data) {
    const url = `${ADMIN_BACKEND_URL}/vendor/fetchUser`;
    return await axios.post(url, data, Util.setupHeaders());
  }

  async updateUser(user) {
    const url = `${ADMIN_BACKEND_URL}/vendor/updateUser`;
    return await axios.post(url, user, Util.setupHeaders());
  }

  async getData(data) {
    const url = `${ADMIN_BACKEND_URL}/vendorProfiling/getUPCount`;
    return await axios.post(url, data, Util.setupHeaders());
  }

  async getTotalUsers(data) {
    const url = `${ADMIN_BACKEND_URL}/vendor/countTotalUsers`;
    return await axios.post(url, data, Util.setupHeaders());
  }

  async getGroupedSubmission(data) {
    const url = `${ADMIN_BACKEND_URL}/vendor/getTotalSubmissions`;
    return await axios.post(url, data, ApiUtil.setupHeaders());
  }

  async getProjectData(data) {
    const url = `${ADMIN_BACKEND_URL}/vendor/getProjectData`;
    return await axios.post(url, data, ApiUtil.setupHeaders());
  }
}
